import i18n from '@/languages/i18n';

export enum OrderProcessState {
  New = 0,
  InProgress = 1,
  Completed = 2
}

export const OrderProcessStateDropdown = () => [
  { id: OrderProcessState.New, title: i18n.t('dropdowns.orderProcessStates.new').toString() },
  {
    id: OrderProcessState.InProgress,
    title: i18n.t('dropdowns.orderProcessStates.inProgress').toString()
  },
  {
    id: OrderProcessState.Completed,
    title: i18n.t('dropdowns.orderProcessStates.completed').toString()
  }
];

export default OrderProcessState;
