














import Vue from 'vue';
import axios from 'axios';

import FieldDefinition from '@/models/FieldDefinition';

interface ArticleParameter {
  Parameter: string;
  // eslint-disable-next-line
  Value: any;
}

export default Vue.extend({
  name: 'ArticleParameterDetails',
  props: ['parameters'],
  data: function () {
    return {
      fieldDefinitions: [] as FieldDefinition[]
    };
  },
  created() {
    this.loadFieldDefinitions();
  },
  methods: {
    loadFieldDefinitions() {
      if (this.articleParameters.length > 0) {
        axios
          .get('/api/FieldDefinitions', {
            params: {
              orderBy: 'externalId',
              equals: 'externalId=' + this.articleParameters.map((x) => x.Parameter).join('||')
            }
          })
          .then((result) => {
            this.fieldDefinitions = result.data.entities;
          });
      }
    },
    validParameter(articleParameter: ArticleParameter): boolean {
      return (
        this.fieldDefinitions.filter((x) => x.externalId == articleParameter.Parameter).length > 0
      );
    },
    fieldDefinitionForParameter(articleParameter: ArticleParameter): FieldDefinition {
      return this.fieldDefinitions.filter((x) => x.externalId == articleParameter.Parameter)[0];
    }
  },
  computed: {
    articleParameters(): ArticleParameter[] {
      if (this.parameters) {
        return JSON.parse(this.parameters);
      }
      return [];
    }
  }
});
