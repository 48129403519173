

































import Vue from 'vue';
import axios from 'axios';
import { SmartTable, Config, State, Column, Sorting, PagingOptions } from 'rey-vue-smarttable';
import { OrderProcessStateDropdown } from '@/models/OrderProcessState';
import StationLoader from '@/router/StationLoader';
import StationType from '@/models/StationType';
import Station from '@/models/Station';
import Coating from '@/models/Coating';
import Order from '@/models/Order';
import ArticleParameterDetails from '@/views/admin/ArticleParameterDetails.vue';
import AuthenticationService from '@/services/AuthenticationService';

interface Article {
  articleId: number;
  number: string;
}

const LoadState = Object.freeze({ NotStarted: 0, Started: 1, Succeeded: 2, Failed: 3 });

export default Vue.extend({
  name: 'orders',
  components: { ArticleParameterDetails, SmartTable },
  data: function () {
    return {
      AuthenticationService: AuthenticationService,
      LoadState,
      smartTableState: new State(new Sorting('dateOfProduction', 'descending')),
      smartTableConfig: new Config(
        (row) => row.id,
        [
          new Column({
            title: this.$t('views.admin.orders.tableHeaders.id').toString(),
            fieldName: 'id',
            fieldType: 'numeric',
            hidden: true
          }),
          new Column({
            title: this.$t('views.admin.orders.tableHeaders.orderNumber').toString(),
            fieldName: 'orderNumber',
            fieldType: 'string'
          }),
          new Column({
            title: this.$t('views.admin.orders.tableHeaders.articleNumber').toString(),
            fieldName: 'articleId',
            fieldType: 'dropdown'
          }),
          new Column({
            title: this.$t('views.admin.orders.tableHeaders.barcode').toString(),
            fieldName: 'barcode',
            fieldType: 'string'
          }),
          new Column({
            title: this.$t('views.admin.orders.tableHeaders.dateOfProduction').toString(),
            fieldName: 'dateOfProduction',
            fieldType: 'date'
          }),
          new Column({
            title: this.$t('views.admin.orders.tableHeaders.count').toString(),
            fieldName: 'count',
            fieldType: 'numeric'
          }),
          new Column({
            title: this.$t('views.admin.orders.tableHeaders.loadingStationGroup').toString(),
            fieldName: 'loadingStationGroup',
            fieldType: 'string'
          }),
          new Column({
            title: this.$t('views.admin.orders.tableHeaders.loadingStationNumber').toString(),
            fieldName: 'loadingStationNumber',
            fieldType: 'dropdown',
            dropdownOptions: []
          }),
          new Column({
            title: this.$t('views.admin.orders.tableHeaders.maskingStationNumber').toString(),
            fieldName: 'maskingStationNumber',
            fieldType: 'dropdown',
            dropdownOptions: []
          }),
          new Column({
            title: this.$t('views.admin.orders.tableHeaders.unloadingStationNumber').toString(),
            fieldName: 'unloadingStationNumber',
            fieldType: 'dropdown',
            dropdownOptions: []
          }),
          new Column({
            title: this.$t('views.admin.orders.tableHeaders.coating').toString(),
            fieldName: 'coatingId',
            fieldType: 'dropdown'
          }),
          new Column({
            title: this.$t('views.admin.orders.tableHeaders.orderProcessState').toString(),
            fieldName: 'orderProcessState',
            fieldType: 'dropdown',
            dropdownOptions: OrderProcessStateDropdown()
          }),
          new Column({
            title: this.$t('views.admin.orders.tableHeaders.isCancelled').toString(),
            fieldName: 'isCancelled',
            fieldType: 'boolean'
          }),
          new Column({
            title: this.$t('views.admin.orders.tableHeaders.articleParameters').toString(),
            fieldName: 'articleParameters',
            fieldType: 'string'
          })
        ],
        new Sorting('dateOfProduction', 'descending'),
        new PagingOptions(100)
      ).withEfApiActions(axios, 'Order'),
      selected: undefined as Order | undefined,
      windowPartLoadState: LoadState.NotStarted,
      articles: [] as Article[],
      articleParametersSmartTableState: new State(new Sorting('fieldDefinitionId', 'descending')),
      articleParametersSmartTable: undefined as Config | undefined
    };
  },
  async created() {
    await this.loadDropdownValues();
  },
  methods: {
    async loadDropdownValues() {
      await axios
        .get('/api/Coating', {
          params: {
            orderBy: 'coatingNumber'
          }
        })
        .then((response) => response.data)
        .then((result) => this.updateCoatings(result.entities));

      this.updateStations(
        StationLoader.Instance().getStations(StationType.Loading),
        'loadingStationNumber'
      );
      this.updateStations(
        StationLoader.Instance().getStations(StationType.Unloading),
        'maskingStationNumber'
      );
      this.updateStations(
        StationLoader.Instance().getStations(StationType.Masking),
        'unloadingStationNumber'
      );
    },

    updateStations(stations: Station[], dropdown: string) {
      const values = stations.map((x) => ({
        id: x.stationNumber as number | null,
        title: this.$t(x.displayNameResourceId).toString()
      }));
      values.unshift({
        id: null,
        title: this.$t('views.admin.orders.fromArticleStandard').toString()
      });

      this.updateColumnDropdownOptions(dropdown, values);
    },
    updateCoatings(colors: Coating[]) {
      const values = colors.map((x) => ({
        id: x.coatingId,
        title: x.description
      }));

      this.updateColumnDropdownOptions('coatingId', values);
    },
    updateColumnDropdownOptions(
      fieldName: string,
      dropdownOptions: Array<{ id: string | number | null; title: string }>
    ) {
      if (this.smartTableConfig && this.smartTableConfig.columns) {
        for (const col of this.smartTableConfig.columns) {
          if (col.fieldName === fieldName) {
            col.dropdownOptions = dropdownOptions;
            break;
          }
        }
      }
    },
    async stateChanged() {
      // @ts-ignore
      var articleIds = this.$refs.smartTable?.visibleRows.map((x: Article) => x.articleId);
      var unknownArticleIds = articleIds.filter(
        (id: number) => !this.articles.some((article) => article.articleId == id)
      );

      if (unknownArticleIds.length > 0) {
        await axios
          .get('/api/Article', {
            params: {
              equals: 'articleId=' + unknownArticleIds.join('||')
            }
          })
          .then((result) => {
            this.articles.push(...result.data.entities);
          });

        // Update dropdown options
        const values = this.articles.map((x: Article) => ({
          id: x.articleId,
          title: x.number
        }));

        this.updateColumnDropdownOptions('articleId', values);
      }
    }
  }
});
